import React, { Suspense } from 'react';
import { Switch, Route,Redirect,useHistory } from 'react-router-dom';

import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';


const HomePage = React.lazy(() => import('./container/Home'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const AboutPage = React.lazy(() => import('./container/The Maison'));
const SpecialsPage = React.lazy(() => import('./container/Specials'));
const RoomsPage = React.lazy(() => import('./container/Rooms'));
const Area = React.lazy(() => import('./container/Area'));
const Policy =  React.lazy(() => import('./container/Policy'));
const Gallery =  React.lazy(() => import('./container/Gallery'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;
  const history = useHistory();
  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        {/* <Navbar CoreData={coreData} /> */}
      
        <Switch>
          <Route path="/accommodations" component={() => <RoomsPage />} />
          <Route path="/specials" component={() => <SpecialsPage />} />
          <Route path="/dining" component={() => <DiningPage />} />
          <Route path="/the-maison" component={() => <AboutPage />} />
          <Route path="/policies" component={() => <Policy />} />
          <Route path="/area" component={() => <Area />} />
          <Route path="/gallery" component={() => <Gallery />} />
          <Route exact path="/" component={() => <HomePage />} />
          <Route path="*" component={() => { 
            history.push('/');
            return null;
          }} />
        </Switch>
        {/* <Footer cmsData={coreData} /> */}
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['core-data']);
// export default withRouter(App);
